<template>
    <div id="manage-alert-page">
        <toolbar-back-button-component :title="$t('manage_alert_page.manage_alert_of_asset', [asset.name])" />

        <div class="pa-4">
            <p class="font-color-medium mb-0 font-12">
                {{ $t('manage_alert_page.list_alerts_setup') }}
            </p>

            <p class="font-color-medium font-12 mb-7">
                {{ $t('manage_alert_page.alerts_shared_with_other_users') }}
            </p>

            <v-text-field
                v-model="search_alert"
                :label="$t('global.search')"
                class="white-text-field mb-2"
                filled
                hide-details
                prepend-inner-icon="mdi-magnify"
            />

            <template v-if="!loading_alerts">
                <template>
                    <p class="font-weight-bold mt-4 mb-2 d-flex align-center">
                        {{ $t('manage_alert_page.total_alerts', [alerts.length]) }}

                        <v-btn v-if="userHasAccess('BASE', this.role_edit) && alerts.length === 0 && !is_mobile"
                               @click="openDialogEditAlert(true)"
                               color="secondary" elevation="0" class="ml-4" small
                        >
                            <span class="primary--text">{{ $t('manage_alert_page.add_alert') }}</span>
                        </v-btn>
                    </p>


                    <div v-for="(alert, index) in alerts" :key="index">
                        <alert-card-component
                            :alert="alert"
                            class="mb-2"
                            v-on:manageDialogEdit="manageDialogEdit"
                            v-on:refresh:alert="prepareFetchAlerts"
                        />
                    </div>
                </template>
            </template>

            <template v-else>
                <v-skeleton-loader class="mb-2" type="chip" />
                <v-skeleton-loader v-for="n in 2" :key="n" class="mb-2" type="list-item-avatar, divider, actions" />
            </template>
        </div>

        <dialog-update-alert-component
            v-if="dialog_alert && userHasAccess('BASE', this.role_edit)"
            ref="DialogUpdateAlertComponent"
            v-on:refresh:alert="prepareFetchAlerts"
        />

        <v-btn
            v-if="userHasAccess('BASE', this.role_edit)"
            class="add-alert-btn"
            color="secondary"
            elevation="0"
            fab
            @click="openDialogEditAlert(true)"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>

        <div class="bottom-hidden-div"></div>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from '@/components/Global/ToolbarBackButtonComponent'
import AlertCardComponent from '@/components/Alert/AlertCardComponent'
import DialogUpdateAlertComponent from '@/components/Alert/DialogUpdateAlertComponent'
import AlertRepository from '../../repositories/AlertRepository'

export default {
    name: 'ManageAlertPage',
    components: { DialogUpdateAlertComponent, AlertCardComponent, ToolbarBackButtonComponent },
    created() {
        this.userHasAccess('BASE', this.role_vision, true)

        this.asset = this.$route.query.asset_module
            ? this.$store.getters['asset_module/assets_by_asset_module_and_id_asset'](
                this.$route.query.asset_module,
                this.$route.params.id
            )
            : this.$store.getters['tanks/getTankById'](this.$route.params.id)

        this.new_alert.id_device = this.asset.devices[0].id_device

        this.prepareFetchAlerts()
        this.dialog_alert = this.new_alert
    },
    data() {
        return {
            switch_badge: true,
            switch_push: true,
            switch_sms: true,
            switch_email: true,

            asset: {},
            headers: [
                { text: this.$t('manage_alert_page.alert'), value: 'name' },
                { text: this.$t('manage_alert_page.date'), value: 'last_activation' }
            ],

            loading_alerts: false,
            measure_to_watch: [],
            type_measure: [
                { text: this.$t('manage_alert_page.limit'), value: 'limit' },
                { text: this.$t('manage_alert_page.list_alerts_setup'), value: 'variation' }
            ],

            is_new_alert: false,
            dialog_alert: {},

            search_alert: '',
            alerts_copy: [],
            alerts: [],

            new_alert: {
                id_device: 0,
                label: '',

                user_alert_preference: {
                    id_subscription_alert_configuration: null,
                    trigger_frequency: -1,
                    notification_badge: true,
                    notification_email: false,
                    notification_sms: false,
                    notification_push: false
                },

                trigger_frequency_default: -1,
                notification_default_badge: true,
                notification_default_email: false,
                notification_default_sms: false,
                notification_default_push: false,
                is_show_in_charts: false,
                configuration: [
                    {
                        id_metric: null,
                        type: null,
                        operator: null,
                        limit: null,
                        unit: null,
                        variation_time: null,
                        time_unites_selected: null
                    }
                ],

                configuration_appearance: {
                    color_untriggered: '#65B265',
                    icon_untriggered: 'access-point-remove theme',
                    color_triggered: '#DF5950',
                    icon_triggered: 'wifi'
                }
            }
        }
    },
    watch: {
        search_alert: {
            handler() {
                if (!this.search_alert) {
                    this.alerts = this.alerts_copy
                } else {
                    let alerts_local = []
                    this.alerts_copy.forEach((alert) => {
                        if (alert.name.includes(this.search_alert)) {
                            alerts_local.push(alert)
                        }
                    })

                    this.alerts = alerts_local
                }
            },
            deep: true
        }
    },
    methods: {
        prepareFetchAlerts() {
            this.loading_alerts = true
            this.$store
                .dispatch('alerts/fetchAlertsByAsset', this.asset)
                .then(() => {
                    this.alerts = this.$store.getters['alerts/alertsByAsset'](this.asset)
                })
                .finally(() => {
                    this.loading_alerts = false
                })
        },

        openDialogEditAlert(is_new_alert, alert = {}) {
            if (is_new_alert) {
                this.dialog_alert = JSON.parse(JSON.stringify(this.new_alert))
            } else {
                this.dialog_alert = JSON.parse(JSON.stringify(alert))
            }

            this.$refs.DialogUpdateAlertComponent.openDialogEditAlert(this.dialog_alert, is_new_alert)
        },

        manageDialogEdit(alert) {
            this.openDialogEditAlert(false, alert)
        },

        editAlert() {
            this.alerts.forEach((alert) => {
                AlertRepository.updateAlert(alert.id_alert, alert)
                    .then(() => {
                        this.showSnackbar('success', this.$t('manage_alert_page.updated'))
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            })
        }
    }
}
</script>

<style>
.add-alert-btn {
    position: fixed !important;
    right: 16px !important;
    bottom: 16px !important;
}

fieldset {
    background: #f0f2f5 !important;
    border: none !important;
}

.low-height-datatable .v-data-table__mobile-row {
    min-height: 40px !important;
}
</style>