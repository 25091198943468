<template>
    <div id="alert-card-component">
        <v-card elevation="1">
            <v-card-title class="py-2">
                <v-btn
                    :color="alert.triggered ?  alert.configuration_appearance.color_triggered : alert.configuration_appearance.color_untriggered"
                    class="unsolicited-btn"
                    elevation="0"
                    fab
                    small
                >
                    <v-icon color="white">
                        mdi-{{ alert.triggered ?  alert.configuration_appearance.icon_triggered : alert.configuration_appearance.icon_untriggered }}
                    </v-icon>
                </v-btn>

                <div class="ml-2 w-70">
                    <p class="font-color-medium font-12 mb-2 card-type-alert-text">
                        {{ $t('alert_card_component.alert_if') }}
                    </p>
                    <p class="font-14 mb-0 card-name-alert-text line-height-16">{{ alert.label }}</p>
                </div>

                <v-spacer />

                <div v-if="userHasAccess('BASE', this.role_edit)" class="card-option-alert">
                    <v-icon @click="openDialogEditAlert()">mdi-pencil</v-icon>

                    <v-icon class="ml-2" @click="dialog_delete_alert = true">mdi-close</v-icon>
                </div>
            </v-card-title>

            <v-divider class="mx-2" color="white" />

            <v-card-text>
                <p class="font-12 my-auto line-height-12">
                    {{ $t('alert_card_component.i_want_alert') }}
                </p>

                <v-row class="ma-0 mt-1">
                    <div>
                        <p class="font-12 ml-1 mb-0">{{ $t('alert_card_component.badge') }}</p>
                        <v-switch
                            v-model="alert.user_alert_preference.notification_badge"
                            :readonly="!userHasAccess('BASE', this.role_vision)"
                            class="mt-0"
                            inset
                            @change="activateAlert('notification_badge')"
                        />
                    </div>

                    <div>
                        <p class="font-12 ml-1 mb-0">{{ $t('alert_card_component.notifications') }}</p>
                        <v-switch
                            v-model="alert.user_alert_preference.notification_push"
                            :readonly="!userHasAccess('BASE', this.role_vision)"
                            class="mt-0"
                            inset
                            @change="activateAlert('notification_push')"
                        />
                    </div>

                    <div>
                        <p class="font-12 ml-2 mb-0">{{ $t('alert_card_component.sms') }}</p>
                        <v-switch
                            v-model="alert.user_alert_preference.notification_sms"
                            :readonly="!userHasAccess('BASE', this.role_vision)"
                            class="mt-0"
                            inset
                            @change="activateAlert('notification_sms')"
                        />
                    </div>

                    <div>
                        <p class="font-12 ml-1 mb-0">{{ $t('alert_card_component.email') }}</p>
                        <v-switch
                            v-model="alert.user_alert_preference.notification_email"
                            :readonly="!userHasAccess('BASE', this.role_vision)"
                            class="mt-0"
                            inset
                            @change="activateAlert('notification_email')"
                        />
                    </div>
                </v-row>

                <v-text-field
                    :label="$t('alert_card_component.frequency')"
                    :value="
                        alert.user_alert_preference.trigger_frequency === -1
                            ? $t('alert_card_component.one_time')
                            : alert.user_alert_preference.trigger_frequency === 6
                            ? $t('alert_card_component.each_day')
                            : $t('alert_card_component.each_data')
                    "
                    filled
                    hide-details
                    readonly
                    @click="dialog_trigger_frequency = true"
                />
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_delete_alert" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase red--text font-weight-bold">
                        {{ $t('alert_card_component.delete_alert') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <span>
                        {{ $t('alert_card_component.wish_delete_alert') }}
                        <span class="font-weight-bold">{{ alert.label }} ?</span>
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_delete_alert = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn :loading="loading_edit" class="btn-error-loader" color="error" text @click="deleteAlert()">
                        <span class="error--text font-weight-bold">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_trigger_frequency" max-width="500px">
            <v-card>
                <v-card-title class="font-weight-bold">
                    <span>{{ $t('alert_card_component.modify_frequency_alert') }}</span>
                </v-card-title>
                <v-card-text>
                    <span>{{ $t('alert_card_component.how_often_frequency') }}</span>

                    <v-radio-group v-model="alert.user_alert_preference.trigger_frequency" class="mt-1">
                        <v-radio :value="-1">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.one_time') }}</span>
                            </template>
                        </v-radio>

                        <v-radio :value="24">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.each_day') }}</span>
                            </template>
                        </v-radio>

                        <v-radio :value="0">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.each_data') }}</span>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <v-alert close-icon="mdi-close" dismissible icon="mdi-alert-outline" text type="warning">
                        {{ $t('alert_card_component.common_frequency') }}
                    </v-alert>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_trigger_frequency = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :loading="edit_loading"
                        class="btn-secondary-loader"
                        color="secondary"
                        text
                        @click="prepareAlertSubscriptionUpdate()"
                    >
                        <span class="secondary--text font-weight-bold">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_activate_alert" max-width="500px" @click:outside="closeDialogActivateAlert()">
            <v-card>
                <v-card-title class="font-weight-bold">
                    <span>{{ $t('alert_card_component.unable_alert') }}</span>
                </v-card-title>
                <v-card-text>
                    <span>
                        {{ $t('alert_card_component.unable_alert_if') }}<strong>{{ alert.label }}</strong>
                        {{ $t('alert_card_component.by') }}
                        <strong>{{ $t('alert_type.' + activate_alert_type) }}</strong>
                    </span>

                    <v-radio-group v-model="alert.user_alert_preference.trigger_frequency" class="mt-1">
                        <v-radio :value="-1">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.one_time') }}</span>
                            </template>
                        </v-radio>

                        <v-radio :value="6">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.each_day') }}</span>
                            </template>
                        </v-radio>

                        <v-radio :value="24">
                            <template v-slot:label>
                                <span class="font-14">{{ $t('alert_card_component.each_data') }}</span>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <v-alert close-icon="mdi-close" dismissible icon="mdi-alert-outline" text type="warning">
                        {{ $t('alert_card_component.common_frequency') }}
                    </v-alert>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeDialogActivateAlert()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :loading="edit_loading"
                        class="btn-secondary-loader"
                        color="secondary"
                        text
                        @click="prepareAlertSubscriptionUpdate()"
                    >
                        <span class="secondary--text font-weight-bold">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AlertRepository from '@/repositories/AlertRepository'

export default {
    name: 'AlertCardComponent',
    props: {
        alert: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog_delete_alert: false,
            loading_edit: false,
            dialog_trigger_frequency: false,
            edit_loading: false,
            dialog_activate_alert: false,
            activate_alert_type: '',
        }
    },
    methods: {
        openDialogEditAlert() {
            this.$emit('manageDialogEdit', this.alert)
        },

        activateAlert(type) {
            if (!this.alert.user_alert_preference[type]) {
                this.updateSubscriptionAlert()
                return
            }
            this.dialog_activate_alert = true
            this.activate_alert_type = type
        },

        closeDialogActivateAlert() {
            this.dialog_activate_alert = false
            this.alert.user_alert_preference[this.activate_alert_type] = false
            this.activate_alert_type = ''
        },

        deleteAlert() {
            this.loading_edit = true
            AlertRepository.deleteAlert(this.alert.id_alert)
                .then(() => {
                    this.showSnackbar('success', this.$t('alert_card_component.deletion'))
                    this.$emit('refresh:alert')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit = false
                    this.dialog_delete_alert = false
                })
        },

        prepareAlertSubscriptionUpdate() {
            if (!this.alert.user_alert_preference.id_subscription_alert_configuration) {
                this.subscribeUserToAlert()
            } else {
                this.updateSubscriptionAlert()
            }
        },

        updateSubscriptionAlert() {
            this.edit_loading = true
            AlertRepository.updateSubscriptionAlert(this.alert.id_alert, this.alert.user_alert_preference)
                .then(() => {
                    this.showSnackbar('success', this.$t('alert_card_component.updated'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.edit_loading = false
                    this.dialog_trigger_frequency = false
                    this.dialog_activate_alert = false
                })
        },

        subscribeUserToAlert() {
            this.edit_loading = true
            AlertRepository.subscribeUserToAlert(this.alert.id_alert, this.alert.user_alert_preference)
                .then((success) => {
                    this.showSnackbar('success', this.$t('alert_card_component.updated'))
                    this.alert.user_alert_preference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.edit_loading = false
                    this.dialog_trigger_frequency = false
                    this.dialog_activate_alert = false
                })
        },
    },
}
</script>

<style>
.card-type-alert-text {
    position: relative;
    top: 7px;
}

.card-name-alert-text {
    position: relative;
    bottom: 9px;
}

.card-option-alert {
    position: absolute;
    right: 10px;
}
</style>
